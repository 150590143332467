import React, {Component} from 'react'
import { DonationPageLayout } from '../../components/layouts'
import { withPreview } from '../../components/common'

class DonationPagePreview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: null,
            sitemap: []
        }
    }

    componentDidMount() {
        this.props.getSitemap().then(sitemap => {
            this.setState({
                sitemap
            })
        })
        this.props.getEntry('pageDonation', this.props.location.search).then(entry => {
            this.initializeDonationPage(entry)
        })
    }

    initializeDonationPage(entry) {
        const page = {
            __typename: 'contentfulPageDonation',
            ...entry.fields,
        }
    
        page.topRichText = entry.fields.topRichText

        page.paperform = entry.fields.paperform

        page.downRichText = entry.fields.downRichText

        this.setState({
            page,
        })
    }

    render() {
        const { page, sitemap } = this.state
        const site = this.props.getSiteInfo()

        return (!this.props.loading && page && <DonationPageLayout site={site} pageDonation={page} preview={true} sitemap={sitemap}/>)
    }
}

export default withPreview(DonationPagePreview)